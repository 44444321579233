* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.app-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100vh;
  width: auto;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  position: relative;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.flex-row-center {
  justify-content: center;
}
.doc-link a:link,
.doc-link a:visited {
  color: blue;
}
.doc-link {
  display: flex;
  justify-content: center;
}

.header-container {
  height: 200px;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  z-index: 1;
  flex: none;
}
.header {
  height: 100%;
  width: 100%;
  opacity: 1;
  display: flex;
  flex-direction: row;
}
.header-logo {
  flex: 1 0 0;
  align-self: center;
  font-family: "Raleway", sans-serif;
  justify-content: center;
  display: flex;
}
.logo-style {
  color: #fff;
  font-size: 50px;
}

.logo-style-tag {
  color: #fff;
  font-size: 14px;
  align-self: center;
}
.header-logo a {
  text-decoration: none;
  display: flex;
  flex-direction: column;
}
.header-logo a img {
  width: 123px;
  height: 127px;
}

.header-logo a:focus-visible {
  text-decoration: none;
  outline: none;
}

.header-logo h1:focus-visible {
  text-decoration: none;
  outline: none;
}
.header-nav {
  flex: 2 0 0;
  display: flex;
  justify-content: flex-end;
}
.raleway-font {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.raleway-style {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
.merriweather-bold {
  font-family: "Merriweather", serif;
  font-style: normal;
}
.merriweather-regular {
  font-family: "Merriweather", serif;
  font-style: normal;
  font-weight: 300;
}
.sacramento-regular {
  font-family: "Sacramento", cursive;
  font-weight: 800;
  font-style: normal;
}

.nav-list {
  list-style: none;
  display: flex;
  margin: 30px;
  color: white;
  align-items: center;
}
.nav-item {
  padding: 15px;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0px;
}
.nav-item a {
  cursor: pointer;
}
.menu-image {
  display: none;
  cursor: pointer;
}
.bg-video {
  bottom: 0;
  height: 100vh;
  left: 0;
  position: absolute;
  width: 100%;
}
.full-film-video {
  align-self: center;
}
.full-film-size {
  cursor: auto;
  width: 100%;
  height: 80vh;
  border-radius: 0px;
  display: block;
  background-color: #fbf7f2;
  object-position: "50% 50%";
}
.video-container {
  flex: none;
  height: 89vh;
  overflow: hidden;
  position: relative;
  width: 100%;
  background-color: #fff;
}
.video-box {
  height: 100vh;
  left: calc(50.00000000000002% - 100% / 2);
  overflow: visible;
  position: absolute;
  top: calc(50.00000000000002% - 100vh / 2);
  width: 100%;
}
.video-text {
  color: #000;
  letter-spacing: 0;
  text-align: start;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding: 120px 80px;
  font-size: 22px;
  line-height: 1.2;
  display: flex;
  position: absolute;
  top: 0;
}
.in-video-text {
  background-color: rgba(193, 224, 200, 1);
  padding: 2rem;
  line-height: 64px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-end;
  color: #121f29;
}
.opacity-style {
  background-color: #222;
  flex: none;
  height: 100vh;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
}
.whatWeDo-container {
  gap: 30px;
  height: 750px;
  padding: 120px;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: start;
  background-color: #fbf7f2;
}
.homeHistory-container {
  gap: 30px;
  height: 650px;
  padding: 80px 120px;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: start;
  background-color: #fff;
}
.whatWeDo-heading {
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  opacity: 1;
  transform: perspective(1200px);
  position: relative;
}
.whatWeDo-heading > h2 {
  font-family: "Raleway", sans-serif;
  font-size: 66px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.2em;
  text-align: start;
  color: #b58c67;
}
.homeHistory-heading > h2 {
  font-family: "Raleway", sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 1.2em;
  text-align: center;
  color: #b58c67;
}
.whatWeDo-box {
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 64px;
  height: min-content;
  justify-content: center;
  max-width: 1240px;
  overflow: visible;
  padding: 0;
  position: relative;
  width: 100%;
}
.homeHistory-box {
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 64px;
  height: min-content;
  justify-content: center;
  max-width: 1240px;
  overflow: visible;
  padding: 0;
  position: relative;
  width: 100%;
}
.homeHistory-content {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.event-wrap {
  flex-flow: column;
  display: flex;
  flex: 3 0 0px;
  gap: 50px;
}
.test {
  flex: none;
  height: auto;
  position: relative;
  width: 100%;
}
.event-list {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: min-content;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: relative;
  gap: 20px;
}
.event-box {
  align-items: flex-start;
  display: flex;
  flex: 1 0 0px;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0px;
  height: min-content;
  justify-content: center;
  padding: 0;
  position: relative;
}
.event-title {
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  transform: none;
  opacity: 1;
  color: #b58c67;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 2em;
}
.event-content {
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  opacity: 0.8;
  transform: none;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 160%;
  color: #b58c67;
}

.whatWeDo-pic {
  border-radius: 32px;
  flex: 2 0 0px;
  max-width: 520px;
  overflow: hidden;
  position: relative;
  transform: perspective(1200px);
  width: 100%;
  will-change: transform;
  height: 425px;
  display: flex;
}
.homeHistory-pic img {
  width: 450px;
  height: 390px;
  border: 3px solid black;
  border: 10px solid #b58c67;
}
.home-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  background-color: #fff;
  gap: 0px;
  height: min-content;
  overflow: hidden;
  padding: 0;
  min-height: 100vh;
  width: auto;
}
.MakeMemories-container {
  display: flex;
  flex: none;
  position: relative;
  width: 100%;
  background-color: #b58c67;
  height: 1289px;
  overflow: visible;
}
.makeMemory-box {
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 10px;
  height: 1150px;
  justify-content: center;
  left: calc(50.00000000000002% - 1172px / 2);
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: calc(48.33204034134991% - 1150px / 2);
  width: 1172px;
}
.makeMemory-top {
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 60px;
  height: 600px;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}

.makeMemory-top-left {
  flex: none;
  height: 600px;
  overflow: hidden;
  position: relative;
  transform: perspective(1200px);
  width: 630px;
  transition: transform 0.5s;
}
.makeMemory-image-container {
  height: 550px;
  left: 72px;
  position: absolute;
  top: calc(52.83333333333336% - 550px / 2);
  width: 555px;
}
.makeMemory-top-left:hover {
  transform: scale(1.1);
}
.section-style {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  place-items: center;
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  opacity: 1;
  user-select: none;
}
.img-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: inherit;
  position: absolute;
  overflow: hidden;
  border-radius: 0;
  user-select: none;
  perspective: 1200px;
  transform: translate(-50%, -50%);
  border: 20px solid #ffffff;
  top: 50%;
  left: 50%;
}

.img-wrapper1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: inherit;
  position: absolute;
  overflow: hidden;
  border-radius: 0;
  user-select: none;
  perspective: 1200px;
  transform: translate(-50%, -50%);
  border: 20px solid #ffffff;
  top: 50%;
  left: 50%;
}
.img-wrapper2 {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: inherit;
  position: absolute;
  overflow: hidden;
  border-radius: 0;
  user-select: none;
  perspective: 1200px;
  transform: translate(-50%, -50%);
  border: 20px solid #ffffff;
  top: 50%;
  left: 50%;
}
.img-list {
  display: flex;
  animation: slide 10s ease infinite;
  -webkit-animation: slide 10s ease infinite;
  width: 100%;
  height: 100%;
}
.img-list2 {
  display: flex;
  animation: leftRightSlide 10s ease infinite;
  -webkit-animation: leftRightSlide 10s ease infinite;
  flex-direction: row;
  height: 100%;
  width: 100%;
}
.img-list img,
.img-list2 img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-position: center;
  object-fit: cover;
  image-rendering: auto;
}
@keyframes slide {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-100%);
  }
  30% {
    transform: translateX(-100%);
  }
  40% {
    transform: translateX(-200%);
  }
  50% {
    transform: translateX(-200%);
  }
  60% {
    transform: translateX(-300%);
  }
  70% {
    transform: translateX(-300%);
  }
  80% {
    transform: translateX(-400%);
  }
  90% {
    transform: translateX(-400%);
  }
  100% {
    transform: translateX(-500%);
  }
}
@keyframes leftRightSlide {
  0% {
    transform: translateX(-400%);
  }
  20% {
    transform: translateX(-300%);
  }
  25% {
    transform: translateX(-300%);
  }
  45% {
    transform: translateX(-200%);
  }
  50% {
    transform: translateX(-200%);
  }
  70% {
    transform: translateX(-100%);
  }
  75% {
    transform: translateX(-100%);
  }
  85% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}
.image-sticker {
  background-color: #ffffff;
  flex: none;
  height: 58px;
  left: 22px;
  opacity: 0.2;
  position: absolute;
  top: 43px;
  transform: rotate(-30deg);
  width: 192px;
}
.makeMemory-top-right {
  align-items: flex-start;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 20px;
  height: min-content;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 470px;
}
.makeMemory-bottom {
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0px;
  height: 531px;
  justify-content: center;
  overflow: visible;
  padding: 0;
  position: relative;
  width: 100%;
}
.makeMemory-bottom-left {
  flex: none;
  height: 432px;
  overflow: hidden;
  position: relative;
  transform: perspective(1200px);
  width: 607px;
  opacity: 1;
}
.makeMemory-bottom-right {
  flex: none;
  height: 531px;
  overflow: visible;
  position: relative;
  transform: perspective(1200px);
  width: 555px;
  opacity: 1;
}
.image-sticker1 {
  background-color: #ffffff;
  flex: none;
  opacity: 0.2;
  transform: rotate(-30deg);
  width: 129px;
  height: 42px;
  position: absolute;
  top: 29px;
  left: 1px;
}
.image-sticker2 {
  background-color: #ffffff;
  flex: none;
  height: 42px;
  left: 1px;
  opacity: 0.2;
  position: absolute;
  top: 29px;
  transform: rotate(-30deg);
  width: 151px;
}
.makeMemory-image-container1 {
  /* height: 390px; */
  height: 354px;
  left: calc(49.7528830313015% - 580px / 2);
  position: absolute;
  top: calc(50.2314814814815% - 390px / 2);
  /* transform: rotate(-4deg); */
  width: 580px;
}
.makeMemory-image-container2 {
  height: 460px;
  left: calc(50.630630630630655% - 510px / 2);
  position: absolute;
  top: calc(48.399246704331475% - 460px / 2);
  /* transform: rotate(4deg); */
  width: 510px;
}
.astrick-style {
  image-rendering: pixelated;
  flex-shrink: 0;
  fill: rgba(0, 0, 0, 1);
  color: rgba(0, 0, 0, 1);
  flex: none;
  height: 40px;
  position: relative;
  width: 130px;
}
.makeMemory-title {
  flex: none;
  height: auto;
  position: relative;
  white-space: pre;
  width: auto;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  transform: none;
}
.makeMemory-title h2 {
  font-size: 62px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.4em;
  text-align: start;
  color: rgb(255, 255, 255);
  margin: 0;
}
.makeMemory-content {
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  transform: none;
  flex: none;
  height: auto;
  opacity: 0.8;
  position: relative;
  white-space: pre-wrap;
  width: 100%;
  word-break: break-word;
  word-wrap: break-word;
}
.makeMemory-content p > span {
  font-size: 16px;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 1);
}
.Testmonial-container {
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 30px;
  height: 480px;
  justify-content: center;
  overflow: hidden;
  padding: 120px 0;
  position: relative;
  width: 100%;
  background-color: #faf7f2;
}
.Testmonial-box {
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 30px;
  height: min-content;
  justify-content: center;
  padding: 0;
  position: relative;
  width: 1170px;
  overflow: hidden;
  mask-image: linear-gradient(to right, #0000 0%, #000 12.5% 87.5%, #0000 100%);
}
.Testmonial-title {
  outline: none;
  display: flex;
  flex-direction: column;
  flex: none;
  height: auto;
  position: relative;
  white-space: pre-wrap;
  width: 773px;
  word-break: break-word;
  word-wrap: break-word;
}
.Testmonial-title h2 {
  font-size: 65px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 1.4em;
  text-align: start;
  color: #b58c67;
  text-decoration: none;
  text-transform: none;
  align-self: center;
}
.Testmonial-title h3 {
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.4em;
  text-align: start;
  color: #b58c67;
  text-decoration: none;
  text-transform: none;
  align-self: center;
}

.testimonial-reviewBox {
  flex: none;
  height: 200px;
  width: calc(320px * 17);
  position: relative;
}
.testimonial-review-section {
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  place-items: center;
  margin: 0px;
  padding: 10px;
  list-style-type: none;
  opacity: 1;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgb(0, 0, 0) 12.5%,
    rgb(0, 0, 0) 87.5%,
    rgba(0, 0, 0, 0) 100%
  );
  overflow: hidden;
}
.review-list {
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-indent: none;
  gap: 10px;
  position: relative;
  flex-direction: row;
  will-change: transform;
  transform: translateX(-0px);
  animation: reviewScroll 40s linear infinite;
  -webkit-animation: reviewScroll 40s linear infinite;
}
.review-list li {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 10px;
  justify-content: flex-start;
  padding: 25px;
  position: relative;
  width: 320px;
  background-color: #f5ecdd;
  opacity: 1;
  border-radius: 20px;
  color: rgb(181, 140, 103);
  flex: 1;
}
.review-list li .list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.review-list li .list-item img {
  width: 20px;
  align-self: center;
}
.review-list li .list-item div {
  display: flex;
  width: 100%;
  height: 85px;
  align-items: start;
  font-size: 15px;
  letter-spacing: 0.2px;
  word-spacing: 0.8px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.review-list li .list-item p {
  display: flex;
  align-items: flex-end;
  font-size: 14px;
}
@keyframes reviewScroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-320px * 6));
  }
}
.footer-container {
  flex: none;
  height: auto;
  position: relative;
  width: 100%;
}
.footer-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 60px;
  height: min-content;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  opacity: 1;
  position: relative;
  background-color: rgb(18, 31, 41);
  align-items: center;
  padding: 50px 20px 50px;
}
.footer-box-top {
  display: flex;
  flex-direction: row;
  gap: 60px;
  width: 100%;
  gap: 120px;
}
.footer-contact {
  color: #fff;
  opacity: 1;
  outline: none;
  flex-direction: column;
  flex-shrink: 0;
  display: flex;
  transform: none;
  justify-content: center;
  flex: 1;
}
.footer-contact div {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-self: flex-end;
}
.footer-contact div span {
  font-weight: 400;
  display: flex;
}
.footer-contact a {
  text-decoration: none;
  color: #fff;
  padding-left: 5px;
}
.footer-map {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  justify-content: flex-end;
  align-self: center;
}
.footer-map h3 {
  color: #fff;
}
.map-address {
  background-color: #fff;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  padding: 0.5em;
}
.vertical-line {
  border: 1px solid #fff;
  mask-image: linear-gradient(to bottom, transparent, black, transparent);
}
.iframeClass {
  border: 2px solid #ffffffe6;
  border-radius: 20px;
  width: 400px;
  height: 180px;
}
.footer-menu {
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 30px;
  height: min-content;
  justify-content: flex-start;
  overflow: visible;
  padding: 0;
  position: relative;
}
.footer-copyright {
  outline: none;
  display: flex;
  justify-content: flex-start;
  flex-shrink: 0;
  color: #fff;
  transform: none;
  opacity: 1;
  gap: 10px;
  font-family: Raleway, sans-serif;
  font-size: 13px;
  flex-direction: column;
  align-items: center;
}
.footer-copyright a {
  text-decoration: underline;
  color: #fff;
}
.footer-navlist {
  color: #fff;
  display: flex;
  gap: 30px;
}
.page_404 {
  padding: 40px;
  background: #fff;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(./src/images/404Gif.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
  text-decoration: none;
}
.contant_box_404 {
  margin-top: -50px;
}
.link-style {
  text-decoration: none;
  color: #fff;
}
.contact-container {
  min-height: 50vh;
  background-color: #fbf7f2;
  flex-flow: column;
  flex: none;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 60px;
  padding: 80px 140px;
  display: flex;
  position: relative;
  overflow: hidden;
}
.contact-heading {
  gap: 10px;
  letter-spacing: 0px;
  text-align: start;
  color: rgb(181, 140, 103);
  font-family: Raleway, sans-serif;
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.2em;
  display: flex;
  flex-direction: column;
}
.contact-map {
  border: 8px solid #fff;
  border-radius: 15px;
  width: 800px;
  height: 400px;
}
.contact-text {
  opacity: 0.8;
  letter-spacing: 0;
  color: #b58c67;
  outline: none;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-start;
  font-size: 35px;
  font-style: italic;
  font-weight: 500;
  line-height: 160%;
  display: flex;
  transform: none;
  font-family: Raleway, sans-serif;
}
.map-box {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-self: center;
  width: 100%;
}
.contact-transport {
  opacity: 0.8;
  letter-spacing: 0;
  color: #b58c67;
  outline: none;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-start;
  font-style: normal;
  display: flex;
  transform: none;
  font-family: Raleway, sans-serif;
}
.transport-display {
  display: flex;
  flex-direction: row;
}
.History-container {
  flex-flow: column;
  flex: none;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 60px;
  display: flex;
  position: relative;
  width: 100%;
}
.History-box {
  flex-flow: column;
  flex: none;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
  display: flex;
  position: relative;
  width: 100%;
}
.History-box-top {
  color: #fff;
  background-color: rgb(18, 31, 41);
  padding: 120px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-self: center;
  width: 100%;
}
.History-box-bottom {
  padding: 80px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
}
.History-heading {
  opacity: 1;
  color: #67aeb5;
  letter-spacing: 2px;
  outline: none;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-start;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 2em;
  display: flex;
  transform: none;
}
.History-text {
  opacity: 0.8;
  letter-spacing: 0;
  outline: none;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-start;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  display: flex;
  transform: none;
}
.History-text img {
  width: 400px;
  height: 230px;
}

.History-text ul {
  flex-direction: column;
  gap: 13px;
  display: flex;
  font-weight: 700;
  list-style: none;
}
.History-text li .boardName {
  font-size: 20px;
}
.History-section {
  background-color: #fbf7f2;
  border-radius: 10px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  mask-image: linear-gradient(to right, #0000 0%, #000 2.5% 90.5%, #0000 100%);
}
.image-text-style {
  display: flex;
  flex-direction: row;
  gap: 25px;
}
.border-btm-poster {
  color: #121f29;
  text-decoration: none;
  border-bottom: 5px solid rgba(113, 144, 120, 100);
}
.border-btm-poster:hover {
  border-bottom: 5px solid #fff;
}
.Rent-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.Rent-container .img-section img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
}
.Rent-container .img-section {
  height: 60vh;
  width: 100%;
  bottom: 0;
  left: 0;
}
.Rent-container .image-text {
  color: #fff;
  letter-spacing: 0;
  text-align: start;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 40px;
  line-height: 1.2;
  display: flex;
  position: absolute;
  font-family: "Merriweather", serif;
  font-weight: 500;
}
.Rent-content {
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding: 80px 140px;
  background-color: #fbf7f2;
}
.Rent-price {
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 40px;
}
.Rent-box {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fbf7f2;
}

.Rent-heading {
  color: #b58c67;
  font-family: "Raleway", sans-serif;
  font-size: 40px;
}
.Rent-pricing {
  color: #b58c67;
  font-family: "Raleway", sans-serif;
  display: flex;
}
.Rent-pricing ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 20px;
  font-weight: 600;
  font-size: 20px;
}
.Rent-conten-section1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.Rent-Collabs {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-content: center;
  flex-basis: 60%;
  font-family: "Raleway", sans-serif;
  justify-content: flex-start;
  background-color: #dfa25f;
  border-radius: 5px;
  padding: 40px 50px;
}
.Rent-Collabs-ul ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #fff;
  font-size: 17px;
}
.Rent-Collabs-ul ul li div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.Rent-Collabs-ul ul li a {
  text-decoration: none;
  color: #fff;
  padding-left: 5px;
}
.Rent-Collabs-ul ul li span {
  display: flex;
}
.Rent-Collabs-ul ul li span img {
  padding-right: 5px;
}
.Rent-conten-section2 {
  display: flex;
  width: 100%;
}
.Rent-calendar-contact {
  display: flex;
  gap: 40px;
}
.Rent-book {
  display: flex;
  gap: 40px;
  flex-direction: column;
}
.Rent-calendar {
  border: solid 6px #fff7d7;
  width: 500px;
  height: 350px;
  border-radius: 7px;
  display: flex;
}
.Rent-calendar .view-container-border {
  background-color: red;
}
.Rent-calendars {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: "Raleway", sans-serif;
}
.Rent-contact {
  color: #fff;
  opacity: 1;
  outline: none;
  flex-direction: column;
  flex-shrink: 0;
  display: flex;
  transform: none;
  justify-content: start;
  flex: 1;
  gap: 20px;
}
.rent-contact-card {
  background-color: rgb(223, 162, 95);
  border-radius: 5px;
  height: 200px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Rent-contact .contact-text,
.rent-card-block {
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 18px;
}
.rent-card-block {
  display: flex;
  flex-direction: row;
}
.rent-card-block div {
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 10px;
}
.rent-card-block div span {
  display: flex;
}
.Rent-contact a {
  text-decoration: none;
  color: #fff;
  padding-left: 5px;
}
.event-content button {
  height: 40px;
  position: relative;
  width: 150px;
  border: 1px solid #b58c67;
  border-radius: 4px;
  display: flex;
  --_g: linear-gradient(#fbf7f2 0 0) no-repeat;
  background: var(--_g) calc(var(--_p, 0%) - 100%) 0%,
    var(--_g) calc(200% - var(--_p, 0%)) 0%,
    var(--_g) calc(var(--_p, 0%) - 100%) 100%,
    var(--_g) calc(200% - var(--_p, 0%)) 100%;
  background-size: 50.5% calc(var(--_p, 0%) / 2 + 0.5%);
  outline-offset: 0.1em;
  transition: background-size 0.4s, background-position 0s 0.4s;
}
.event-content button:active {
  box-shadow: 0 0 9e9Q inset #0009;
  background-color: #fbf7f2;
  color: #fff;
}
.event-content button:hover {
  --_p: 100%;
  transition: background-position 0.4s, background-size 0s;
}
.event-content button a {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #b58c67;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.4em;
  font-family: "Raleway", sans-serif;
}
.Photographs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
}
.Photographs img {
  width: 380px;
  height: 300px;
}
:root {
  --bar-width: 25px;
  --bar-height: 2px;
  --hamburger-gap: 6px;
  --foreground: #333;
  --background: white;
  --hamburger-margin: 15px;
  --hamburger-top: 95px;
  --animation-timing: 200ms ease-in-out;
  --hamburger-height: calc(var(--bar-height) * 3 + var(--hamburger-gap) * 2);
}

@media all and (max-width: 1000px) {
  .whatWeDo-box {
    flex-direction: column;
    height: min-content;
  }
  .whatWeDo-container {
    height: auto;
  }
  .whatWeDo-pic {
    flex: none;
  }
  .nav-list {
    transition: translate var(--animation-timing);
    translate: 200%;
    padding: 0.5rem 1.05rem;
    padding-top: calc(var(--hamburger-height) + var(--hamburger-margin) + 1rem);
    background-color: rgb(18, 31, 41);
    color: var(--background);
    min-height: 46vh;
    display: flex;
    flex-direction: column;
    margin: 0px;
    top: 70px;
    position: relative;
  }
  .makeMemory-box {
    height: auto;
    top: 2%;
  }
  .makeMemory-top {
    flex-direction: column-reverse;
    height: auto;
  }
  .makeMemory-bottom {
    flex-direction: column;
    height: auto;
  }
  .makeMemory-top-right {
    width: 450px;
  }
  .makeMemory-image-container {
    left: 0;
    top: 0;
    width: 450px;
    height: 450px;
  }
  .makeMemory-top-left {
    height: 450px;
    width: 450px;
    opacity: 1;
  }
  .MakeMemories-container {
    height: 1900px;
  }
  .image-sticker,
  .image-sticker1,
  .image-sticker2 {
    display: none;
  }
  .image-text-style {
    flex-direction: column !important;
    display: flex !important;
  }
  .History-box-top,
  .History-box-bottom {
    padding: 30px;
  }
  .History-text img {
    width: auto;
    height: auto;
    max-width: 300px;
    max-height: 280px;
  }
  .menu-image {
    display: flex;
  }
  .hamburger-menu {
    --x-width: calc(var(--hamburger-height) * 1.41421356237);
    display: flex;
    flex-direction: column;
    gap: var(--hamburger-gap);
    width: max-content;
    top: var(--hamburger-top);
    right: var(--hamburger-margin);
    z-index: 2;
    cursor: pointer;
    justify-content: center;
    position: absolute;
  }

  .hamburger-menu:has(input:focus-visible)::before,
  .hamburger-menu:has(input:focus-visible)::after,
  .hamburger-menu input:focus-visible {
    border: 1px solid var(--background);
    box-shadow: 0 0 0 1px var(--foreground);
  }

  .hamburger-menu::before,
  .hamburger-menu::after,
  .hamburger-menu input {
    content: "";
    width: var(--bar-width);
    height: var(--bar-height);
    background-color: var(--background);
    border-radius: 9999px;
    transform-origin: left center;
    transition: opacity var(--animation-timing), width var(--animation-timing),
      rotate var(--animation-timing), translate var(--animation-timing),
      background-color var(--animation-timing);
  }

  .hamburger-menu input {
    appearance: none;
    padding: 0;
    margin: 0;
    outline: none;
    pointer-events: none;
  }

  .hamburger-menu:has(input:checked)::before {
    rotate: 45deg;
    width: var(--x-width);
    translate: 0 calc(var(--bar-height) / -2);
  }

  .hamburger-menu:has(input:checked)::after {
    rotate: -45deg;
    width: var(--x-width);
    translate: 0 calc(var(--bar-height) / 2);
  }

  .hamburger-menu input:checked {
    opacity: 0;
    width: 0;
  }
  .hamburger-menu:has(input:checked) + .nav-list {
    translate: 0;
  }
  .logo-style {
    font-size: 35px;
  }
  .header-logo {
    padding-left: 10px;
  }
  .homeHistory-container {
    height: auto;
  }
  .homeHistory-content {
    flex-direction: column;
  }
  .Rent-content {
    padding: 30px;
    align-items: center;
  }
  .Rent-conten-section1 {
    flex-direction: column;
    gap: 30px;
  }
  .Rent-calendar-contact {
    flex-direction: column;
    gap: 30px;
  }
  .Rent-calendar {
    width: 340px;
  }
  .contact-container {
    padding: 50px;
  }
  .contact-map {
    width: 550px;
  }
  .Rent-container .image-text {
    font-size: 35px;
    padding: 20px;
  }
  .Photographs {
    grid-template-columns: auto;
  }
}
/* mobile view */
@media all and (max-width: 500px) {
  .whatWeDo-container {
    padding: 30px;
  }
  .makeMemory-title h2 {
    font-size: 42px;
  }
  .makeMemory-top-right {
    width: 300px;
  }
  .makeMemory-image-container {
    left: 0;
    top: 0;
    width: 300px;
    height: 300px;
  }
  .makeMemory-top-left {
    height: 300px;
    width: 300px;
  }
  .image-sticker,
  .image-sticker1,
  .image-sticker2 {
    display: none;
  }
  .makeMemory-image-container1 {
    width: 342px;
    height: 217px;
  }
  .img-wrapper1 {
    left: 85%;
  }
  .makeMemory-image-container2 {
    width: 310px;
    height: 300px;
    top: calc(48.3992% - 321px);
    left: calc(50.6306% - 154px);
  }
  .makeMemory-bottom-right {
    height: 300px;
  }
  .MakeMemories-container {
    height: 1400px;
  }
  .makeMemory-box {
    height: auto;
    top: calc(48.332% - 630px);
  }
  .footer-box-top {
    flex-direction: column;
    justify-content: center;
    gap: 65px;
  }
  .vertical-line {
    display: none;
  }
  .iframeClass {
    width: 300px;
    align-self: center;
  }
  .footer-contact div {
    align-self: center;
  }
  .footer-menu {
    display: none;
  }
  .image-text-style {
    flex-direction: column !important;
    display: flex !important;
  }
  .History-box-top {
    padding: 10px;
  }
  .History-box-bottom {
    padding: 10px;
  }
  .History-text img {
    width: auto;
    height: auto;
    max-width: 400px;
    max-height: 230px;
  }
  .menu-image {
    display: flex;
  }
  .hamburger-menu {
    top: 90px;
  }
  .nav-list {
    width: 100%;
  }
  .header-nav div {
    /* width: 100%; */
  }
  .logo-style {
    font-size: 30px;
  }
  .header-logo {
    padding-left: 10px;
  }
  .homeHistory-pic img {
    width: 320px;
  }
  .contact-map {
    width: 300px;
  }
  .Rent-container .image-text {
    font-size: 35px;
    padding: 20px;
    justify-content: end;
  }
  .rent-card-block {
    flex-direction: column;
    display: flex;
  }
  .in-video-text {
    line-height: 45px;
  }
  .in-video-text h5 {
    font-size: 20px !important;
  }
  .Photographs img {
    height: 200px;
    width: 340px;
  }
  .full-film-size {
    height: 100%;
    object-fit: cover;
  }
}
@media all and (max-width: 1500px) {
  .homeHistory-container {
    height: auto;
  }
}
@media (min-width: 800px) and (max-width: 1400px) {
  .rent-card-block {
    flex-direction: column;
    display: flex;
  }
  .Rent-conten-section1,
  .Rent-calendar-contact {
    flex-direction: column;
    gap: 30px;
  }
  .Rent-calendar {
    width: 700px;
    height: 550px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.app-container {
  flex-flow: column;
  justify-content: flex-start;
  width: auto;
  min-height: 100vh;
  padding: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.flex-column {
  flex-direction: column;
  display: flex;
}

.flex-row {
  flex-direction: row;
  gap: 50px;
  display: flex;
}

.flex-row-center {
  justify-content: center;
}

.doc-link a:link, .doc-link a:visited {
  color: #00f;
}

.doc-link {
  justify-content: center;
  display: flex;
}

.header-container {
  z-index: 1;
  flex: none;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.header {
  opacity: 1;
  flex-direction: row;
  width: 100%;
  height: 100%;
  display: flex;
}

.header-logo {
  flex: 1 0 0;
  justify-content: center;
  align-self: center;
  font-family: Raleway, sans-serif;
  display: flex;
}

.logo-style {
  color: #fff;
  font-size: 50px;
}

.logo-style-tag {
  color: #fff;
  align-self: center;
  font-size: 14px;
}

.header-logo a {
  flex-direction: column;
  text-decoration: none;
  display: flex;
}

.header-logo a img {
  width: 123px;
  height: 127px;
}

.header-logo a:focus-visible, .header-logo h1:focus-visible {
  outline: none;
  text-decoration: none;
}

.header-nav {
  flex: 2 0 0;
  justify-content: flex-end;
  display: flex;
}

.raleway-font {
  font-optical-sizing: auto;
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.raleway-style {
  font-optical-sizing: auto;
  font-family: Raleway, sans-serif;
  font-style: normal;
}

.merriweather-bold {
  font-family: Merriweather, serif;
  font-style: normal;
}

.merriweather-regular {
  font-family: Merriweather, serif;
  font-style: normal;
  font-weight: 300;
}

.sacramento-regular {
  font-family: Sacramento, cursive;
  font-style: normal;
  font-weight: 800;
}

.nav-list {
  color: #fff;
  align-items: center;
  margin: 30px;
  list-style: none;
  display: flex;
}

.nav-item {
  letter-spacing: 0;
  padding: 15px;
  font-size: 18px;
  line-height: 24px;
}

.nav-item a {
  cursor: pointer;
}

.menu-image {
  cursor: pointer;
  display: none;
}

.bg-video {
  width: 100%;
  height: 100vh;
  position: absolute;
  bottom: 0;
  left: 0;
}

.full-film-video {
  align-self: center;
}

.full-film-size {
  cursor: auto;
  object-position: "50% 50%";
  background-color: #fbf7f2;
  border-radius: 0;
  width: 100%;
  height: 80vh;
  display: block;
}

.video-container {
  background-color: #fff;
  flex: none;
  width: 100%;
  height: 89vh;
  position: relative;
  overflow: hidden;
}

.video-box {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: calc(50% - 50vh);
  left: 0%;
  overflow: visible;
}

.video-text {
  color: #000;
  letter-spacing: 0;
  text-align: start;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding: 120px 80px;
  font-size: 22px;
  line-height: 1.2;
  display: flex;
  position: absolute;
  top: 0;
}

.in-video-text {
  color: #121f29;
  background-color: #c1e0c8;
  flex-direction: column;
  align-self: flex-end;
  align-items: flex-start;
  max-width: 500px;
  padding: 2rem;
  line-height: 64px;
  display: flex;
}

.opacity-style {
  opacity: .5;
  background-color: #222;
  flex: none;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.whatWeDo-container {
  background-color: #fbf7f2;
  flex-flow: column;
  flex: none;
  justify-content: flex-start;
  align-items: start;
  gap: 30px;
  height: 750px;
  padding: 120px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.homeHistory-container {
  background-color: #fff;
  flex-flow: column;
  flex: none;
  justify-content: flex-start;
  align-items: start;
  gap: 30px;
  height: 650px;
  padding: 80px 120px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.whatWeDo-heading {
  opacity: 1;
  outline: none;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-start;
  display: flex;
  position: relative;
  transform: perspective(1200px);
}

.whatWeDo-heading > h2 {
  letter-spacing: 0;
  text-align: start;
  color: #b58c67;
  font-family: Raleway, sans-serif;
  font-size: 66px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2em;
}

.homeHistory-heading > h2 {
  letter-spacing: 0;
  text-align: center;
  color: #b58c67;
  font-family: Raleway, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2em;
}

.whatWeDo-box {
  flex-flow: row;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 64px;
  width: 100%;
  max-width: 1240px;
  height: min-content;
  padding: 0;
  display: flex;
  position: relative;
  overflow: visible;
}

.homeHistory-box {
  flex-flow: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 64px;
  width: 100%;
  max-width: 1240px;
  height: min-content;
  padding: 0;
  display: flex;
  position: relative;
  overflow: visible;
}

.homeHistory-content {
  flex-direction: row;
  gap: 40px;
  display: flex;
}

.event-wrap {
  flex-flow: column;
  flex: 3 0 0;
  gap: 50px;
  display: flex;
}

.test {
  flex: none;
  width: 100%;
  height: auto;
  position: relative;
}

.event-list {
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  height: min-content;
  padding: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.event-box {
  flex-flow: column;
  flex: 1 0 0;
  justify-content: center;
  align-items: flex-start;
  gap: 0;
  height: min-content;
  padding: 0;
  display: flex;
  position: relative;
}

.event-title {
  opacity: 1;
  color: #b58c67;
  letter-spacing: 1px;
  outline: none;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-start;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 2em;
  display: flex;
  transform: none;
}

.event-content {
  opacity: .8;
  letter-spacing: 0;
  color: #b58c67;
  outline: none;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-start;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  display: flex;
  transform: none;
}

.whatWeDo-pic {
  will-change: transform;
  border-radius: 32px;
  flex: 2 0 0;
  width: 100%;
  max-width: 520px;
  height: 425px;
  display: flex;
  position: relative;
  overflow: hidden;
  transform: perspective(1200px);
}

.homeHistory-pic img {
  border: 10px solid #b58c67;
  width: 450px;
  height: 390px;
}

.home-container {
  background-color: #fff;
  flex-flow: column;
  justify-content: flex-start;
  gap: 0;
  width: auto;
  height: min-content;
  min-height: 100vh;
  padding: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.MakeMemories-container {
  background-color: #b58c67;
  flex: none;
  width: 100%;
  height: 1289px;
  display: flex;
  position: relative;
  overflow: visible;
}

.makeMemory-box {
  flex-flow: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 1172px;
  height: 1150px;
  padding: 0;
  display: flex;
  position: absolute;
  top: calc(48.332% - 575px);
  left: calc(50% - 586px);
  overflow: hidden;
}

.makeMemory-top {
  flex-flow: row;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 60px;
  width: 100%;
  height: 600px;
  padding: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.makeMemory-top-left {
  flex: none;
  width: 630px;
  height: 600px;
  transition: transform .5s;
  position: relative;
  overflow: hidden;
  transform: perspective(1200px);
}

.makeMemory-image-container {
  width: 555px;
  height: 550px;
  position: absolute;
  top: calc(52.8333% - 275px);
  left: 72px;
}

.makeMemory-top-left:hover {
  transform: scale(1.1);
}

.section-style {
  opacity: 1;
  user-select: none;
  flex-direction: row;
  place-items: center;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.img-wrapper {
  width: 100%;
  height: 100%;
  padding: inherit;
  user-select: none;
  perspective: 1200px;
  border: 20px solid #fff;
  border-radius: 0;
  margin: 0;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.img-wrapper1 {
  width: 100%;
  height: 100%;
  padding: inherit;
  user-select: none;
  perspective: 1200px;
  border: 20px solid #fff;
  border-radius: 0;
  flex-direction: column;
  margin: 0;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.img-wrapper2 {
  width: 100%;
  height: 100%;
  padding: inherit;
  user-select: none;
  perspective: 1200px;
  border: 20px solid #fff;
  border-radius: 0;
  margin: 0;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.img-list {
  width: 100%;
  height: 100%;
  animation: 10s infinite slide;
  display: flex;
}

.img-list2 {
  flex-direction: row;
  width: 100%;
  height: 100%;
  animation: 10s infinite leftRightSlide;
  display: flex;
}

.img-list img, .img-list2 img {
  border-radius: inherit;
  object-position: center;
  object-fit: cover;
  image-rendering: auto;
  width: 100%;
  height: 100%;
  display: block;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }

  10% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-100%);
  }

  30% {
    transform: translateX(-100%);
  }

  40% {
    transform: translateX(-200%);
  }

  50% {
    transform: translateX(-200%);
  }

  60% {
    transform: translateX(-300%);
  }

  70% {
    transform: translateX(-300%);
  }

  80% {
    transform: translateX(-400%);
  }

  90% {
    transform: translateX(-400%);
  }

  100% {
    transform: translateX(-500%);
  }
}

@keyframes leftRightSlide {
  0% {
    transform: translateX(-400%);
  }

  20% {
    transform: translateX(-300%);
  }

  25% {
    transform: translateX(-300%);
  }

  45% {
    transform: translateX(-200%);
  }

  50% {
    transform: translateX(-200%);
  }

  70% {
    transform: translateX(-100%);
  }

  75% {
    transform: translateX(-100%);
  }

  85% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(0);
  }
}

.image-sticker {
  opacity: .2;
  background-color: #fff;
  flex: none;
  width: 192px;
  height: 58px;
  position: absolute;
  top: 43px;
  left: 22px;
  transform: rotate(-30deg);
}

.makeMemory-top-right {
  flex-flow: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 470px;
  height: min-content;
  padding: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.makeMemory-bottom {
  flex-flow: row;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 0;
  width: 100%;
  height: 531px;
  padding: 0;
  display: flex;
  position: relative;
  overflow: visible;
}

.makeMemory-bottom-left {
  opacity: 1;
  flex: none;
  width: 607px;
  height: 432px;
  position: relative;
  overflow: hidden;
  transform: perspective(1200px);
}

.makeMemory-bottom-right {
  opacity: 1;
  flex: none;
  width: 555px;
  height: 531px;
  position: relative;
  overflow: visible;
  transform: perspective(1200px);
}

.image-sticker1 {
  opacity: .2;
  background-color: #fff;
  flex: none;
  width: 129px;
  height: 42px;
  position: absolute;
  top: 29px;
  left: 1px;
  transform: rotate(-30deg);
}

.image-sticker2 {
  opacity: .2;
  background-color: #fff;
  flex: none;
  width: 151px;
  height: 42px;
  position: absolute;
  top: 29px;
  left: 1px;
  transform: rotate(-30deg);
}

.makeMemory-image-container1 {
  width: 580px;
  height: 354px;
  position: absolute;
  top: calc(50.2315% - 195px);
  left: calc(49.7529% - 290px);
}

.makeMemory-image-container2 {
  width: 510px;
  height: 460px;
  position: absolute;
  top: calc(48.3992% - 230px);
  left: calc(50.6306% - 255px);
}

.astrick-style {
  image-rendering: pixelated;
  fill: #000;
  color: #000;
  flex: none;
  width: 130px;
  height: 40px;
  position: relative;
}

.makeMemory-title {
  white-space: pre;
  outline: none;
  flex-direction: column;
  flex: none;
  justify-content: flex-start;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  transform: none;
}

.makeMemory-title h2 {
  letter-spacing: 0;
  text-align: start;
  color: #fff;
  margin: 0;
  font-size: 62px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4em;
}

.makeMemory-content {
  opacity: .8;
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
  outline: none;
  flex-direction: column;
  flex: none;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  transform: none;
}

.makeMemory-content p > span {
  letter-spacing: .02em;
  color: #fff;
  font-size: 16px;
}

.Testmonial-container {
  background-color: #faf7f2;
  flex-flow: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
  height: 480px;
  padding: 120px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.Testmonial-box {
  flex-flow: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 1170px;
  height: min-content;
  padding: 0;
  display: flex;
  position: relative;
  overflow: hidden;
  mask-image: linear-gradient(to right, #0000 0%, #000 12.5% 87.5%, #0000 100%);
}

.Testmonial-title {
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
  outline: none;
  flex-direction: column;
  flex: none;
  width: 773px;
  height: auto;
  display: flex;
  position: relative;
}

.Testmonial-title h2 {
  letter-spacing: 0;
  text-align: start;
  color: #b58c67;
  text-transform: none;
  align-self: center;
  font-size: 65px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4em;
  text-decoration: none;
}

.Testmonial-title h3 {
  letter-spacing: 0;
  text-align: start;
  color: #b58c67;
  text-transform: none;
  align-self: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4em;
  text-decoration: none;
}

.testimonial-reviewBox {
  flex: none;
  width: 5440px;
  height: 200px;
  position: relative;
}

.testimonial-review-section {
  opacity: 1;
  place-items: center;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 10px;
  list-style-type: none;
  display: flex;
  overflow: hidden;
  mask-image: linear-gradient(to right, #0000 0%, #000 12.5% 87.5%, #0000 100%);
}

.review-list {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  text-indent: none;
  will-change: transform;
  flex-direction: row;
  gap: 10px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  animation: 40s linear infinite reviewScroll;
  display: flex;
  position: relative;
  transform: translateX(0);
}

.review-list li {
  opacity: 1;
  color: #b58c67;
  background-color: #f5ecdd;
  border-radius: 20px;
  flex-flow: column;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  width: 320px;
  padding: 25px;
  display: flex;
  position: relative;
}

.review-list li .list-item {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  display: flex;
}

.review-list li .list-item img {
  align-self: center;
  width: 20px;
}

.review-list li .list-item div {
  letter-spacing: .2px;
  word-spacing: .8px;
  text-overflow: ellipsis;
  align-items: start;
  width: 100%;
  height: 85px;
  font-size: 15px;
  display: flex;
  overflow: hidden;
}

.review-list li .list-item p {
  align-items: flex-end;
  font-size: 14px;
  display: flex;
}

@keyframes reviewScroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-1920px);
  }
}

.footer-container {
  flex: none;
  width: 100%;
  height: auto;
  position: relative;
}

.footer-box {
  opacity: 1;
  background-color: #121f29;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  width: 100%;
  height: min-content;
  padding: 50px 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.footer-box-top {
  flex-direction: row;
  gap: 120px;
  width: 100%;
  display: flex;
}

.footer-contact {
  color: #fff;
  opacity: 1;
  outline: none;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  display: flex;
  transform: none;
}

.footer-contact div {
  flex-direction: column;
  align-self: flex-end;
  gap: 15px;
  display: flex;
}

.footer-contact div span {
  font-weight: 400;
  display: flex;
}

.footer-contact a {
  color: #fff;
  padding-left: 5px;
  text-decoration: none;
}

.footer-map {
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-self: center;
  gap: 5px;
  display: flex;
  position: relative;
}

.footer-map h3 {
  color: #fff;
}

.map-address {
  background-color: #fff;
  border-radius: 8px;
  padding: .5em;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
}

.vertical-line {
  border: 1px solid #fff;
  mask-image: linear-gradient(#0000, #000, #0000);
}

.iframeClass {
  border: 2px solid #ffffffe6;
  border-radius: 20px;
  width: 400px;
  height: 180px;
}

.footer-menu {
  flex-flow: row;
  flex: none;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  height: min-content;
  padding: 0;
  display: flex;
  position: relative;
  overflow: visible;
}

.footer-copyright {
  color: #fff;
  opacity: 1;
  outline: none;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-family: Raleway, sans-serif;
  font-size: 13px;
  display: flex;
  transform: none;
}

.footer-copyright a {
  color: #fff;
  text-decoration: underline;
}

.footer-navlist {
  color: #fff;
  gap: 30px;
  display: flex;
}

.page_404 {
  background: #fff;
  padding: 40px;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url("404Gif.ce2cf3eb.gif");
  background-position: center;
  height: 400px;
}

.four_zero_four_bg h1, .four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  background: #39ac31;
  margin: 20px 0;
  padding: 10px 20px;
  text-decoration: none;
  display: inline-block;
  color: #fff !important;
}

.contant_box_404 {
  margin-top: -50px;
}

.link-style {
  color: #fff;
  text-decoration: none;
}

.contact-container {
  background-color: #fbf7f2;
  flex-flow: column;
  flex: none;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 60px;
  min-height: 50vh;
  padding: 80px 140px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.contact-heading {
  letter-spacing: 0;
  text-align: start;
  color: #b58c67;
  flex-direction: column;
  gap: 10px;
  font-family: Raleway, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2em;
  display: flex;
}

.contact-map {
  border: 8px solid #fff;
  border-radius: 15px;
  width: 800px;
  height: 400px;
}

.contact-text {
  opacity: .8;
  letter-spacing: 0;
  color: #b58c67;
  outline: none;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-start;
  font-family: Raleway, sans-serif;
  font-size: 35px;
  font-style: italic;
  font-weight: 500;
  line-height: 160%;
  display: flex;
  transform: none;
}

.map-box {
  flex-direction: column;
  justify-content: center;
  align-self: center;
  gap: 15px;
  width: 100%;
  display: flex;
}

.contact-transport {
  opacity: .8;
  letter-spacing: 0;
  color: #b58c67;
  outline: none;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-start;
  font-family: Raleway, sans-serif;
  font-style: normal;
  display: flex;
  transform: none;
}

.transport-display {
  flex-direction: row;
  display: flex;
}

.History-container {
  flex-flow: column;
  flex: none;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 60px;
  width: 100%;
  display: flex;
  position: relative;
}

.History-box {
  flex-flow: column;
  flex: none;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  display: flex;
  position: relative;
}

.History-box-top {
  color: #fff;
  background-color: #121f29;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  gap: 30px;
  width: 100%;
  padding: 120px;
  display: flex;
}

.History-box-bottom {
  flex-direction: column;
  gap: 50px;
  width: 100%;
  padding: 80px;
  display: flex;
}

.History-heading {
  opacity: 1;
  color: #67aeb5;
  letter-spacing: 2px;
  outline: none;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-start;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 2em;
  display: flex;
  transform: none;
}

.History-text {
  opacity: .8;
  letter-spacing: 0;
  outline: none;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-start;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  display: flex;
  transform: none;
}

.History-text img {
  width: 400px;
  height: 230px;
}

.History-text ul {
  flex-direction: column;
  gap: 13px;
  font-weight: 700;
  list-style: none;
  display: flex;
}

.History-text li .boardName {
  font-size: 20px;
}

.History-section {
  background-color: #fbf7f2;
  border-radius: 10px;
  flex-direction: column;
  gap: 30px;
  padding: 40px;
  display: flex;
  mask-image: linear-gradient(to right, #0000 0%, #000 2.5% 90.5%, #0000 100%);
}

.image-text-style {
  flex-direction: row;
  gap: 25px;
  display: flex;
}

.border-btm-poster {
  color: #121f29;
  border-bottom: 5px solid #719078;
  text-decoration: none;
}

.border-btm-poster:hover {
  border-bottom: 5px solid #fff;
}

.Rent-container {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.Rent-container .img-section img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

.Rent-container .img-section {
  width: 100%;
  height: 60vh;
  bottom: 0;
  left: 0;
}

.Rent-container .image-text {
  color: #fff;
  letter-spacing: 0;
  text-align: start;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: Merriweather, serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  display: flex;
  position: absolute;
}

.Rent-content {
  background-color: #fbf7f2;
  flex-direction: column;
  gap: 80px;
  padding: 80px 140px;
  display: flex;
}

.Rent-price {
  flex-direction: column;
  gap: 40px;
  width: 100%;
  height: 100%;
  display: flex;
}

.Rent-box {
  background-color: #fbf7f2;
  flex-direction: column;
  display: flex;
  position: relative;
}

.Rent-heading {
  color: #b58c67;
  font-family: Raleway, sans-serif;
  font-size: 40px;
}

.Rent-pricing {
  color: #b58c67;
  font-family: Raleway, sans-serif;
  display: flex;
}

.Rent-pricing ul {
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
  font-size: 20px;
  font-weight: 600;
  display: flex;
}

.Rent-conten-section1 {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.Rent-Collabs {
  background-color: #dfa25f;
  border-radius: 5px;
  flex-direction: column;
  flex-basis: 60%;
  place-content: center flex-start;
  gap: 30px;
  padding: 40px 50px;
  font-family: Raleway, sans-serif;
  display: flex;
}

.Rent-Collabs-ul ul {
  color: #fff;
  flex-direction: column;
  gap: 10px;
  font-size: 17px;
  display: flex;
}

.Rent-Collabs-ul ul li div {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.Rent-Collabs-ul ul li a {
  color: #fff;
  padding-left: 5px;
  text-decoration: none;
}

.Rent-Collabs-ul ul li span {
  display: flex;
}

.Rent-Collabs-ul ul li span img {
  padding-right: 5px;
}

.Rent-conten-section2 {
  width: 100%;
  display: flex;
}

.Rent-calendar-contact {
  gap: 40px;
  display: flex;
}

.Rent-book {
  flex-direction: column;
  gap: 40px;
  display: flex;
}

.Rent-calendar {
  border: 6px solid #fff7d7;
  border-radius: 7px;
  width: 500px;
  height: 350px;
  display: flex;
}

.Rent-calendar .view-container-border {
  background-color: red;
}

.Rent-calendars {
  flex-direction: column;
  gap: 20px;
  font-family: Raleway, sans-serif;
  display: flex;
}

.Rent-contact {
  color: #fff;
  opacity: 1;
  outline: none;
  flex-direction: column;
  flex: 1;
  justify-content: start;
  gap: 20px;
  display: flex;
  transform: none;
}

.rent-contact-card {
  background-color: #dfa25f;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  height: 200px;
  display: flex;
}

.Rent-contact .contact-text, .rent-card-block {
  flex-direction: column;
  gap: 15px;
  font-size: 18px;
  display: flex;
}

.rent-card-block {
  flex-direction: row;
  display: flex;
}

.rent-card-block div {
  flex-direction: column;
  align-self: center;
  gap: 10px;
  font-weight: 400;
  display: flex;
}

.rent-card-block div span {
  display: flex;
}

.Rent-contact a {
  color: #fff;
  padding-left: 5px;
  text-decoration: none;
}

.event-content button {
  --_g: linear-gradient(#fbf7f2 0 0) no-repeat;
  background: var(--_g) calc(var(--_p, 0%)  - 100%) 0%, var(--_g) calc(200% - var(--_p, 0%)) 0%, var(--_g) calc(var(--_p, 0%)  - 100%) 100%, var(--_g) calc(200% - var(--_p, 0%)) 100%;
  background-size: 50.5% calc(var(--_p, 0%) / 2 + 0.5%);
  outline-offset: .1em;
  border: 1px solid #b58c67;
  border-radius: 4px;
  width: 150px;
  height: 40px;
  transition: background-size .4s, background-position 0s .4s;
  display: flex;
  position: relative;
}

.event-content button:active {
  color: #fff;
  background-color: #fbf7f2;
  box-shadow: inset 0 0 9000000000q #0009;
}

.event-content button:hover {
  --_p: 100%;
  transition: background-position .4s, background-size;
}

.event-content button a {
  color: #b58c67;
  letter-spacing: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: Raleway, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4em;
  text-decoration: none;
  display: flex;
}

.Photographs {
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
}

.Photographs img {
  width: 380px;
  height: 300px;
}

:root {
  --bar-width: 25px;
  --bar-height: 2px;
  --hamburger-gap: 6px;
  --foreground: #333;
  --background: white;
  --hamburger-margin: 15px;
  --hamburger-top: 95px;
  --animation-timing: .2s ease-in-out;
  --hamburger-height: calc(var(--bar-height) * 3 + var(--hamburger-gap) * 2);
}

@media (width <= 1000px) {
  .whatWeDo-box {
    flex-direction: column;
    height: min-content;
  }

  .whatWeDo-container {
    height: auto;
  }

  .whatWeDo-pic {
    flex: none;
  }

  .nav-list {
    transition: translate var(--animation-timing);
    padding: .5rem 1.05rem;
    padding-top: calc(var(--hamburger-height)  + var(--hamburger-margin)  + 1rem);
    color: var(--background);
    background-color: #121f29;
    flex-direction: column;
    min-height: 46vh;
    margin: 0;
    display: flex;
    position: relative;
    top: 70px;
    translate: 200%;
  }

  .makeMemory-box {
    height: auto;
    top: 2%;
  }

  .makeMemory-top {
    flex-direction: column-reverse;
    height: auto;
  }

  .makeMemory-bottom {
    flex-direction: column;
    height: auto;
  }

  .makeMemory-top-right {
    width: 450px;
  }

  .makeMemory-image-container {
    width: 450px;
    height: 450px;
    top: 0;
    left: 0;
  }

  .makeMemory-top-left {
    opacity: 1;
    width: 450px;
    height: 450px;
  }

  .MakeMemories-container {
    height: 1900px;
  }

  .image-sticker, .image-sticker1, .image-sticker2 {
    display: none;
  }

  .image-text-style {
    flex-direction: column !important;
    display: flex !important;
  }

  .History-box-top, .History-box-bottom {
    padding: 30px;
  }

  .History-text img {
    width: auto;
    max-width: 300px;
    height: auto;
    max-height: 280px;
  }

  .menu-image {
    display: flex;
  }

  .hamburger-menu {
    --x-width: calc(var(--hamburger-height) * 1.41421);
    gap: var(--hamburger-gap);
    width: max-content;
    top: var(--hamburger-top);
    right: var(--hamburger-margin);
    z-index: 2;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    display: flex;
    position: absolute;
  }

  .hamburger-menu:has(input:focus-visible):before, .hamburger-menu:has(input:focus-visible):after, .hamburger-menu input:focus-visible {
    border: 1px solid var(--background);
    box-shadow: 0 0 0 1px var(--foreground);
  }

  .hamburger-menu:before, .hamburger-menu:after, .hamburger-menu input {
    content: "";
    width: var(--bar-width);
    height: var(--bar-height);
    background-color: var(--background);
    transform-origin: 0;
    transition: opacity var(--animation-timing), width var(--animation-timing), rotate var(--animation-timing), translate var(--animation-timing), background-color var(--animation-timing);
    border-radius: 9999px;
  }

  .hamburger-menu input {
    appearance: none;
    pointer-events: none;
    outline: none;
    margin: 0;
    padding: 0;
  }

  .hamburger-menu:has(input:checked):before {
    width: var(--x-width);
    rotate: 45deg;
    translate: 0 calc(var(--bar-height) / -2);
  }

  .hamburger-menu:has(input:checked):after {
    width: var(--x-width);
    rotate: -45deg;
    translate: 0 calc(var(--bar-height) / 2);
  }

  .hamburger-menu input:checked {
    opacity: 0;
    width: 0;
  }

  .hamburger-menu:has(input:checked) + .nav-list {
    translate: 0;
  }

  .logo-style {
    font-size: 35px;
  }

  .header-logo {
    padding-left: 10px;
  }

  .homeHistory-container {
    height: auto;
  }

  .homeHistory-content {
    flex-direction: column;
  }

  .Rent-content {
    align-items: center;
    padding: 30px;
  }

  .Rent-conten-section1, .Rent-calendar-contact {
    flex-direction: column;
    gap: 30px;
  }

  .Rent-calendar {
    width: 340px;
  }

  .contact-container {
    padding: 50px;
  }

  .contact-map {
    width: 550px;
  }

  .Rent-container .image-text {
    padding: 20px;
    font-size: 35px;
  }

  .Photographs {
    grid-template-columns: auto;
  }
}

@media (width <= 500px) {
  .whatWeDo-container {
    padding: 30px;
  }

  .makeMemory-title h2 {
    font-size: 42px;
  }

  .makeMemory-top-right {
    width: 300px;
  }

  .makeMemory-image-container {
    width: 300px;
    height: 300px;
    top: 0;
    left: 0;
  }

  .makeMemory-top-left {
    width: 300px;
    height: 300px;
  }

  .image-sticker, .image-sticker1, .image-sticker2 {
    display: none;
  }

  .makeMemory-image-container1 {
    width: 342px;
    height: 217px;
  }

  .img-wrapper1 {
    left: 85%;
  }

  .makeMemory-image-container2 {
    width: 310px;
    height: 300px;
    top: calc(48.3992% - 321px);
    left: calc(50.6306% - 154px);
  }

  .makeMemory-bottom-right {
    height: 300px;
  }

  .MakeMemories-container {
    height: 1400px;
  }

  .makeMemory-box {
    height: auto;
    top: calc(48.332% - 630px);
  }

  .footer-box-top {
    flex-direction: column;
    justify-content: center;
    gap: 65px;
  }

  .vertical-line {
    display: none;
  }

  .iframeClass {
    align-self: center;
    width: 300px;
  }

  .footer-contact div {
    align-self: center;
  }

  .footer-menu {
    display: none;
  }

  .image-text-style {
    flex-direction: column !important;
    display: flex !important;
  }

  .History-box-top, .History-box-bottom {
    padding: 10px;
  }

  .History-text img {
    width: auto;
    max-width: 400px;
    height: auto;
    max-height: 230px;
  }

  .menu-image {
    display: flex;
  }

  .hamburger-menu {
    top: 90px;
  }

  .nav-list {
    width: 100%;
  }

  .logo-style {
    font-size: 30px;
  }

  .header-logo {
    padding-left: 10px;
  }

  .homeHistory-pic img {
    width: 320px;
  }

  .contact-map {
    width: 300px;
  }

  .Rent-container .image-text {
    justify-content: end;
    padding: 20px;
    font-size: 35px;
  }

  .rent-card-block {
    flex-direction: column;
    display: flex;
  }

  .in-video-text {
    line-height: 45px;
  }

  .in-video-text h5 {
    font-size: 20px !important;
  }

  .Photographs img {
    width: 340px;
    height: 200px;
  }

  .full-film-size {
    object-fit: cover;
    height: 100%;
  }
}

@media (width <= 1500px) {
  .homeHistory-container {
    height: auto;
  }
}

@media (width >= 800px) and (width <= 1400px) {
  .rent-card-block {
    flex-direction: column;
    display: flex;
  }

  .Rent-conten-section1, .Rent-calendar-contact {
    flex-direction: column;
    gap: 30px;
  }

  .Rent-calendar {
    width: 700px;
    height: 550px;
  }
}
/*# sourceMappingURL=index.0066a0f6.css.map */
